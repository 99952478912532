import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

// Components
import {
  BoxDefault,
  ContentAlt,
  ButtonGoAlt,
  ButtonAltSecondary,
} from 'components/Elements'

// Language
import { getLanguage } from 'services/language'

// CSS
import './Services.scss'

const Services = ({ compact }) => {
  const {
    servicesNL,
    servicesEN,
    servicesButtonTextNL,
    servicesButtonTextEN
  } = useStaticQuery(graphql`
    {
      servicesNL: allWordpressPage(sort: { fields: acf___sort_number, order: ASC }, filter: { parent_element: {slug: {in: ["diensten", "services"]}}, wpml_current_locale: { eq: "nl_NL" } }) {
        edges {
          node {
            ...servicesContent
          }
        }
      }

      servicesEN: allWordpressPage(sort: { fields: acf___sort_number, order: ASC }, filter: { parent_element: {slug: {in: ["diensten", "services"]}}, wpml_current_locale: { eq: "en_US" } }) {
        edges {
          node {
            ...servicesContent
          }
        }
      }

      servicesButtonTextNL: wordpressWpComponenten(
        slug: { eq: "diensten-text" }
        wpml_current_locale: { eq: "nl_NL" }
      ) {
        ...servicesButtonTextContent
      }

      servicesButtonTextEN: wordpressWpComponenten(
        slug: { eq: "diensten-text" }
        wpml_current_locale: { eq: "en_US" }
      ) {
        ...servicesButtonTextContent
      }
    }

    fragment servicesContent on wordpress__PAGE {
      wordpress_id
      path
      title
      acf {
        textIntro: text_intro
        textTitleBlock: title_block
        icon {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 40) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }

    fragment servicesButtonTextContent on wordpress__wp_componenten {
      acf {
        textButton: text_button
      }
    }
  `)

  const language = getLanguage()
  const servicesContent = language === 'nl_NL' ? servicesNL.edges : servicesEN.edges
  const servicesButtonTextContent = language === 'nl_NL' ? servicesButtonTextNL : servicesButtonTextEN

  return (
    <div className="services">
      {compact ? (
        <ServicesCompact services={servicesContent} buttonText={servicesButtonTextContent.acf.textButton} />
      ) : (
        <ServicesFull services={servicesContent} buttonText={servicesButtonTextContent.acf.textButton} />
      )}
    </div>
  )
}

const ServicesFull = ({ services, buttonText }) => (
  <div className="row">
    <div className="col-12 col-lg-6">
      <Service isActive fields={services[0].node} buttonText={buttonText} />
      <div className="row">
        <div className="col-12 col-lg-6" />
        <div className="col-12 col-lg-6">
          <Service fields={services[3].node} buttonText={buttonText} />
        </div>
      </div>
    </div>
    <div className="col-12 col-lg-6 pt-lg-5">
      <div className="row">
        <div className="col-12 col-lg-6">
          <Service fields={services[1].node} buttonText={buttonText} />
          <Service fields={services[4].node} buttonText={buttonText} />
        </div>
        <div className="col-12 col-lg-6 pt-lg-5">
          <Service fields={services[2].node} buttonText={buttonText} />
          <Service fields={services[5].node} buttonText={buttonText} />
        </div>
      </div>
    </div>
  </div>
)

const ServicesCompact = ({ services }) => (
  <div className="row">
    <div className="col-12 col-lg-6">
      {/* <Service />
      <Service /> */}

    </div>
    <div className="col-12 col-lg-6 pt-lg-5">
      <div className="pt-lg-5">
        {/* <Service />
        <Service /> */}

      </div>
    </div>
  </div>
)

const Service = ({
  isActive,
  buttonText,
  fields: {
    path,
    acf: { textTitleBlock, textIntro, icon },
  },
}) => (
  <div className="pt-2">
    <BoxDefault className={`p-1 mt-4${isActive ? ` box-active` : ``}`}>
      <div className="service-header position-relative pt-3 px-3">
        <div className="service-header-icon position-absolute">
          <Img fluid={icon.localFile.childImageSharp.fluid} />
        </div>
        <h3 className="text-right pl-5">
          <div dangerouslySetInnerHTML={{ __html: textTitleBlock }} />
        </h3>
      </div>
      <ContentAlt className="service-content px-3">
        <div dangerouslySetInnerHTML={{ __html: textIntro }} />
      </ContentAlt>
      <div className="service-footer pb-3 px-3 text-right">
        {isActive ? (
          <ButtonAltSecondary to={path}>
            <u>{buttonText}</u>
          </ButtonAltSecondary>
        ) : (
          <>
            <ButtonGoAlt to={path} />
          </>
        )}
      </div>
    </BoxDefault>
  </div>
)

export default Services
